import BillSummary from "../../components/BillSummary/BillSummary"
import styles from './billing.module.scss';
import ClickAndCollectIcon from '../../assets/images/Vector_click_and_collect.svg';
import ShipOrderIcon from '../../assets/images/Group_ship_order.svg';
import { useContext, useEffect, useState } from "react";
import { DownOutlined, EnvironmentFilled, PhoneFilled, UpOutlined } from "@ant-design/icons";
import ShipmentDetails from "./ShipmentDetails";
import { CartContext } from "../../config/context/CartContext";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { redirectToCheckout } from "../../config/axios/productApis";

const Billing = () => {

    const [clickAndCollectTab, setClickAndCollectTab] = useState<boolean>(false);
    const [activeTab,setActiveTab] = useState<string>("pickup");
    const [pickupDay,setPickupDay] = useState<string>("today");
    const [pickupTime,setPickupTime] = useState<string>('10:00-12:30');
    const [deliveryType,setDeliveryType] = useState<string>("free");
    const [pickUpOnFocus, setPickUpOnFocus] = useState<boolean>(false);
    const [shipmentOnFocus, setShipmentOnFocus] = useState<boolean>(true)
    const [isMobile, setIsMobile] = useState(false)
    const [deliveryFee, setDeliveryFee] = useState<number>(0);
    const navigate = useNavigate();

    const {getCartTotal,getCartTotalItems,cartItems} = useContext(CartContext)
 
    //choose the screen size 
    const handleResize = () => {
      if (window.innerWidth < 720) {
          setIsMobile(true)
      } else {
          setIsMobile(false)
      }
    }

    const getDeliveryTime = () => {
        // Define the cutoff time (15:00 hrs)
        const cutoffTime = dayjs().set('hour', 15).set('minute', 0).set('second', 0);

        // Get the current system time
        const currentTime = dayjs();

        if (currentTime.isBefore(cutoffTime)) {
            // If the order is placed before 15:00 hrs, schedule delivery for the same day
            return 'Today';
          } else {
            // If the order is placed after 15:00 hrs, schedule delivery for the next day
            return 'Tomorrow';
          }
    }

     // Get dates for the next 5 days
     const dates = [];
     for (let i = 0; i < 6; i++) {
         const date = dayjs().add(i, 'day');
         const formattedDate = date.format('D/M');
         dates.push(formattedDate);
     }

     const findDayforDate = (dateString:string) => {
         // Split the dateString into day and month parts
        const [day, month] = dateString.split('/').map(Number);

        // Get the current year
        const currentYear = dayjs().year();

        // Create a dayjs object with the current year, month, and day
        const dateObject = dayjs(`${currentYear}-${month}-${day}`, 'YYYY-MM-DD');

        // Format the date to get the day of the week
        const dayOfWeek = dateObject.format('dddd'); // e.g., "Friday"

        console.log('DOW',dayOfWeek)
        return dayOfWeek;
     }
    
    // create an event listener
    useEffect(() => {
      window.addEventListener("resize", handleResize)
      if(localStorage.getItem("token") === null || localStorage.getItem("token") === undefined){
          navigate('/login');
      }
    },[])

    const checkout = () => {
        redirectToCheckout(localStorage.getItem('userId')!,localStorage.getItem('token')!.slice(1,-1),
        {'productList':cartItems.map((item) => {return {
          productId: item.id,
          productName: item.title,
          quantity: item.quantity,
          productImage:item.imageUrl
        }}),'successUrl':`https://arrfinefoods.com/success`,'cancelUrl':`https://arrfinefoods.com/cart`,'totalamount':getCartTotal() * 105}).then((response:any) => {
        
          if(response.status === 200) {
            window.location.href = response.data.CheckoutUrl;
          }
          
        })
    }

    const shipmentFeeHandler = (shipmentFee:number) => {
         setDeliveryFee(shipmentFee);
    }


  return (
        <div className={styles.billing_page_container}>
            <div>
                <div className={styles.billing_page_header}>
                    <div><b>Select order method</b></div>
                    <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                </div>
                <div className={styles.tab_container}>
                    <div className={clickAndCollectTab ? styles.tab_active : styles.tab_inactive} onClick={() => {setActiveTab('pickup');setClickAndCollectTab(true);setPickUpOnFocus(true);setShipmentOnFocus(false)}}>
                        <img src={ClickAndCollectIcon}/>
                        <div>Click & Collect</div>
                    </div>
                    <div className={clickAndCollectTab ? styles.tab_inactive : styles.tab_active} onClick={() => {setActiveTab('shipment');setClickAndCollectTab(false);setPickUpOnFocus(false);setShipmentOnFocus(true)}}>
                    <img src={ShipOrderIcon} />
                        <div>Ship Order</div>
                    </div>
                </div>
                <div className={styles.pickup_container}>
                    <div className={styles.pickup_heading_container}>
                        <div>Pickup</div>
                        {pickUpOnFocus ? <UpOutlined /> : <DownOutlined />}
                    </div>
                { pickUpOnFocus && <>
                        <div className={styles.pickup_header_desc}>Store Address and Contact Details</div>
                        <div className={styles.pickup_details_container}>
                        
                            <div className={styles.address_details_container}>
                            <EnvironmentFilled className={styles.pickup_container_icons}/>
                            <div className={styles.address_container}>
                                <div><b>Address</b></div>
                                <div>14-16 Victoria Rd, St Budeaux, Plymouth PL5 1RG, United Kingdom</div>
                            </div>
        
                            </div>
                            <div className={styles.contact_details_container}>
                            <PhoneFilled className={styles.pickup_container_icons}/>
                            <div className={styles.address_container}>
                                <div><b>Phone</b></div>
                                <div>+44 123 456 789 </div>
                            </div>
                            </div>
                        </div>
                        <div className={styles.divider_line} />
                        <div className={styles.order_collecting_times_container}>
                        <div className={styles.order_collection_title}>Order Collecting time & Details</div>
                        <div>Choose your favorable date and time</div>
                        <div className={styles.pickup_day_container}>
                            <div className={pickupDay === 'today' ? styles.pickup_day_active : styles.pickup_day_inactive} onClick={() => {setPickupDay('today')}}>
                                <div>Today</div>
                                <div>{dates[0]}</div>
                            </div>
                            <div className={pickupDay === 'monday' ? styles.pickup_day_active : styles.pickup_day_inactive} onClick={() => {setPickupDay('monday')}}>
                                <div>{findDayforDate(dates[1])}</div>
                                <div>{dates[1]}</div>
                            </div>
                            <div className={pickupDay === 'tuesday' ? styles.pickup_day_active : styles.pickup_day_inactive} onClick={() => {setPickupDay('tuesday')}}>
                                <div>{findDayforDate(dates[2])}</div>
                                <div>{dates[2]}</div>
                            </div>
                            <div className={pickupDay === 'wednesday' ? styles.pickup_day_active : styles.pickup_day_inactive} onClick={() => {setPickupDay('wednesday')}}>
                            <div>{findDayforDate(dates[3])}</div>
                                <div>{dates[3]}</div>
                            </div>
                            <div className={pickupDay === 'thursday' ? styles.pickup_day_active : styles.pickup_day_inactive} onClick={() => {setPickupDay('thursday')}}>
                            <div>{findDayforDate(dates[4])}</div>
                                <div>{dates[4]}</div>
                            </div>
                            <div className={pickupDay === 'friday' ? styles.pickup_day_active : styles.pickup_day_inactive} onClick={() => {setPickupDay('friday')}}>
                            <div>{findDayforDate(dates[5])}</div>
                                <div>{dates[5]}</div>
                            </div>
                        </div>
                        <div className={styles.pickup_time_container}>
                            <div className={pickupTime === '10:00-12:30' ? styles.pickup_time_active : styles.pickup_time_inactive} onClick={() => {setPickupTime('10:00-12:30')}}>10:00 - 12:30</div>
                            <div className={pickupTime === '13:00-15:30' ? styles.pickup_time_active : styles.pickup_time_inactive} onClick={() => {setPickupTime('13:00-15:30')}}>01:00 - 03:30</div>
                            <div className={pickupTime === '17:00-20:30' ? styles.pickup_time_active : styles.pickup_time_inactive} onClick={() => {setPickupTime('17:00-20:30')}}>05:00 - 08:30</div>
                            <div className={pickupTime === '21:00-22:30' ? styles.pickup_time_active : styles.pickup_time_inactive} onClick={() => {setPickupTime('21:00-22:30')}}>09:00 - 10:30</div>
                        </div>
                        <button type='submit' className={styles.form_submit_button} style={{marginTop:'50px'}} onClick={() => {checkout()}}>Continue to Payment</button>
                        </div>
                    </>}
                    

                </div>
                <div className={styles.pickup_container}>
                <div className={styles.pickup_heading_container}>
                        <div>Delivery Address</div>
                        {shipmentOnFocus ? <UpOutlined /> : <DownOutlined />}
                </div>
                {shipmentOnFocus && <>
                    <ShipmentDetails shipmentFeeHandler={shipmentFeeHandler}/>
                    <div className={styles.pickup_container}>
                        <div className={styles.pickup_heading_container}>
                            <div>Schedule delivery time</div>
                            {shipmentOnFocus ? <UpOutlined /> : <DownOutlined />}
                        </div>
                        <div>
                            <div className={deliveryType === 'free' ? styles.delivery_option_active : styles.delivery_option} onClick={() => setDeliveryType('free')}>
                                <div>
                                    <div>
                                        <div><b>{getDeliveryTime()}, 10 AM - 9 PM</b></div>
                                        <div style={{fontSize:'12px',marginTop:5}}>{deliveryFee === 0 ? 'Free Delivery' : `Delivery Fee: \u00A3 ${deliveryFee}`}</div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className={deliveryType === 'fast' ? styles.delivery_option_active : styles.delivery_option} onClick={() => setDeliveryType('fast')}>
                                <div>
                                    <div>
                                        <div><b>Today, 8 AM - 9 AM</b></div>
                                        <div style={{fontSize:'12px',marginTop:5}}>Fast Delivery</div>
                                    </div>
                                </div>
                                <div>
                                <b>Price: {`\u00A3`} 3</b>
                                </div>
                            </div> */}
                        </div>

                    </div>
                    
                </>}
                </div>
            </div>
        {!isMobile && <BillSummary isButtonAvailable={false} billDetails={{
                 totalPrice: getCartTotal(),
                 totalQuantity: getCartTotalItems(),
                 shipmentFee: deliveryFee
                }}/>}
        </div>
  )
}

export default Billing