import axios from "axios"

export const loginUser = async (userName:string, password:string) => {
    try{
        return axios.post(`${process.env.REACT_APP_ARRFINE_BASE_URL}/users/login`,{
            userEmail: userName,
            password: password
        })
    }
    catch(e){
        throw e;
    }
}

export const signupUser = async (data:any) => {
    try{
        return axios.post(`${process.env.REACT_APP_ARRFINE_BASE_URL}/users`,data)
    }
    catch(e){
        throw e;
    }
}

export const getUserDetails = async (userId:string) => {
    try{
       return axios.get(`${process.env.REACT_APP_ARRFINE_BASE_URL}/users/details/${userId}`,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')!.slice(1,-1)}`
        }
       })
    }catch(e){
        throw e;
    }
}

export const contactUsFormSubmission = async (data:any) => {
    try{
        return axios.post(`${process.env.REACT_APP_ARRFINE_BASE_URL}/submit`,data)
    }
    catch(e){
        throw e;
    }
}

export const getAllAddressforUser = async (userId:string) => {
    try{
        return axios.get(`${process.env.REACT_APP_ARRFINE_BASE_URL}/address/all/user/${userId}`,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')!.slice(1,-1)}`
            }
        })
    }catch(e){
        throw e;
    }
}

export const addAddressforUser = async (userId:string,addressData:any) => {
    try{
        return axios.post(`${process.env.REACT_APP_ARRFINE_BASE_URL}/address/user`,{
            userId : userId,
            userAddress: {
                streetAddress: addressData.secondLane,
                firstName: addressData.firstName,
                secondName: addressData.lastName,
                aptFlatName: addressData.firstLane,
                city: addressData.city,
                state: addressData.state,
                zipCode: addressData.zipCode,
                email: addressData.email,
                phNumber: addressData.phoneNumber
            }
        },{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')!.slice(1,-1)}`
            }
        })
    }catch(e){
        throw e;
    }
}

export const updateAddressForUser = async (addressId:string,userId:string, addressData:any) => {
    try{
        return axios.put(`${process.env.REACT_APP_ARRFINE_BASE_URL}/address/user/${addressId}`,{
            userId: userId,
            userAddress: {
                streetAddress: addressData.secondLane,
                firstName: addressData.firstName,
                secondName: addressData.lastName,
                aptFlatName: addressData.firstLane,
                city: addressData.city,
                state: addressData.state,
                zipCode: addressData.zipCode,
                email: addressData.email,
                phNumber: addressData.phoneNumber
            }
        },{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')!.slice(1,-1)}`
            }
        })
    }catch(e){
        throw e;
    }
}