// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topLevelMessage_top_level_message_container__3-gQo {
  background-color: #0071CE;
  padding: 25px 27.8%;
  display: flex;
  flex-direction: row;
  gap: 25px;
  color: white;
}

@media (max-width: 768px) {
  .topLevelMessage_top_level_message_container__3-gQo {
    background-color: #0071CE;
    padding: 25px 8.8%;
    display: flex;
    flex-direction: row;
    gap: 25px;
    color: white;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/TopLevelMessage/topLevelMessage.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,YAAA;AACJ;;AAEA;EACI;IACI,yBAAA;IACA,kBAAA;IACA,aAAA;IACA,mBAAA;IACA,SAAA;IACA,YAAA;EACN;AACF","sourcesContent":[".top_level_message_container{\n    background-color: #0071CE;\n    padding: 25px 27.8%;\n    display: flex;\n    flex-direction: row;\n    gap: 25px;\n    color: white;\n}\n\n@media (max-width: 768px) {\n    .top_level_message_container{\n        background-color: #0071CE;\n        padding: 25px 8.8%;\n        display: flex;\n        flex-direction: row;\n        gap: 25px;\n        color: white;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top_level_message_container": `topLevelMessage_top_level_message_container__3-gQo`
};
export default ___CSS_LOADER_EXPORT___;
