import { Carousel, Rate } from 'antd'
import styles from './homepage.module.scss';
import classNames from 'classnames';

const Testimonials = () => {
    const sampleTestimonials = [
        {
            name: 'Abdul Kaium Apon',
            location: 'London',
            description: `The best thing is that they have halal and fresh meat like mutton, lamb, goat, chicken and beef etc. The staff also provide very good service as well as I am very happy that they have all kinds of Asian food. Sri Lankan, Indian, Bangladeshi\'s river fish, spices, vegetables, household items, traditional sweets, prayer items and beverages.It can be said that all in on`,
            rating: 5
        },
        {
            name: 'Ajo M J',
            location: 'Birmingham',
            description: 'excellent Asian store മലയാളി കട - everything is there Rice ,Indian Groceries, kerala snacks, Masalas, spices, fresh vegetable, Frozen vegetable, Frozen fish, fresh meat, fresh chicken',
            rating: 4
        },
        {
            name: 'Jayjay Seetan',
            location: 'Dublin',
            description: 'Fabulous find. About time we had a great Sdian supermarket focusing on Indian cuisine',
            rating: 5
        },
        {
            name: 'Ravi S',
            location: 'Manchester',
            description: 'This store has all types of Malayali and Indian food products available along with fresh vegetables and fresh meat , the way they arranged we can easily find out what we need mainly the staff members are Soo helpful and too good. I felt they located in a right place.',
            rating: 5
        },
        {
            name: 'Riya Suhe',
            location: 'London',
            description: 'The staff at ARR Fine Foods are very friendly and helpful. They have a wide variety of products from various Asian countries. Their prices are also fair, and they have a great selection of fresh vegetables and meat.',
            rating: 4
        }
    ]
  return (
    <div className={styles.testimonial_section_container}>
        <div className={styles.testimonial_heading_container}>
            <div>TESTIMONIAL</div>
            <h3>What our Beloved
            Customer says About Us</h3>
            <div>Shopping here is like having a farmers' market at my doorstep—fresh, convenient, and always reliable!</div>
        </div>
        <Carousel  arrows infinite={false} slidesToShow={1} className={classNames(styles.category_carousal,styles.testimonial_carousal)} dots={false}>
            {sampleTestimonials.map((testimonial) =>{
                return (
                <div className={styles.testimonial_container}>
                        <div>{testimonial.name}</div>
                        <div>{testimonial.location}</div>
                        <div><Rate disabled={true} value={testimonial.rating}/></div>
                        <div>{testimonial.description}</div>
                </div>
                )
            })}
        </Carousel>
    </div>
  )
}

export default Testimonials