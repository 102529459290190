import 'react-toastify/dist/ReactToastify.css';
import { toast,ToastContainer,Bounce } from 'react-toastify';

const ToasterComponent = () => {

  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      transition={Bounce}
    />
  )
}

export {ToasterComponent,toast,Bounce}