import axios from "axios";

interface GetListofProductsProps {
    limit ?: number;
    offset ?: number;
    search ?: string;
    filterParams?:{
        minPrice?:string;
        maxPrice?:string;
    }
}

export const getListofProducts = async({limit=10,offset=0,search='',filterParams}:GetListofProductsProps) => {
    try{
        const response = await axios.get(`${process.env.REACT_APP_ARRFINE_BASE_URL}/product?${limit ?`size=${limit}` : ''}${offset ? `&page=${offset}`:''}${search ? `&searchQuery=${search}`:''}${filterParams?.minPrice ? `&minPrice=${filterParams?.minPrice}`:''}${filterParams?.maxPrice ? `&maxPrice=${filterParams?.maxPrice}`:''}`);
        return response
    }
    catch(e){
        return e;
    }

}

export const getProductById = async(id: string) => {
    try{
        const response = await axios.get(`${process.env.REACT_APP_ARRFINE_BASE_URL}/product/${id}`);
        return response
    }
    catch(e){
        return e;
    }
}

export const getProductCategories = async () => {
    try{
        const response = await axios.get(`${process.env.REACT_APP_ARRFINE_BASE_URL}/category/entities`);
        return response
    }
    catch(e){
        return e;
    }
}

export const getProductCategoryById = async (id:string) => {
    try{
        const response = await axios.get(`${process.env.REACT_APP_ARRFINE_BASE_URL}/category/${id}`);
        return response
    }
    catch(e){
        return e;
    }
}

export const getProductsInCategory = async (categoryId:string,filterParams?:GetListofProductsProps['filterParams'],limit?:number,offset?:number) => {
   try{
      const response = await axios.get(`${process.env.REACT_APP_ARRFINE_BASE_URL}/product/entities/sort/${categoryId}?${limit ?`size=${limit}` : ''}${offset ? `&page=${offset}`:''}${filterParams?.minPrice ? `&minPrice=${filterParams?.minPrice}`:''}${filterParams?.maxPrice ? `&maxPrice=${filterParams?.maxPrice}`:''}`);
      return response;
   }
   catch(e){
    return e;
   }
}

export const getCartDetails = async (id:string) => {
    try{
        const response = await axios.get(`${process.env.REACT_APP_PRODUCT_BASE_URL}/cart/${id}`);
        return response
    }
    catch(e){
        return e;
    }
}

export const redirectToCheckout = async (userId:string,token:string,data:any) => {
    try{
        const response = await axios.post(`${process.env.REACT_APP_ARRFINE_BASE_URL}/order/${userId}`,data,{headers: {
            Authorization: `Bearer ${token}`
        }});
        return response
    }
    catch(e){
        return e;
    }
}