import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button'
import styles from './homepage.module.scss';
import { Card, Carousel, List, MenuProps, Select } from 'antd';
import VeggieBasket from '../../assets/images/image 12 2_veggie_basket.png';
import BreadLoaf from '../../assets/images/Bread 3_bread_loaf.png';
import MeatPacket from '../../assets/images/Freah Meat 1_meat_pack.png';
import RiceBag from '../../assets/images/Rice 1_rice_bag.png';
import MasalaPacket from '../../assets/images/image 18_masala_box.png';
import { useNavigate } from 'react-router-dom';
import { getProductCategories } from '../../config/axios/productApis';

interface CategoryCarousalProps {
    isHeaderNeeded?: boolean,
    circleClassName?: string,
    imageClassName?: string,
    callbackFunction?: any,
    isHomePage?: boolean,
}

const CategoryCarousal = ({ isHeaderNeeded = true, circleClassName = '', imageClassName = '', callbackFunction, isHomePage = false }: CategoryCarousalProps) => {

    const [isMobile, setIsMobile] = useState(false)
    const [categoryList, setCategoryList] = useState([]);

    //choose the screen size 
    const handleResize = () => {
        if (window.innerWidth < 720) {
            console.log('It is a mobile screen')
            setIsMobile(true)
        } else {
            console.log('It is not a mobile screen')
            setIsMobile(false)
        }
    }

    useEffect(() => {
        handleResize();
        getProductCategories().then((categoryResponse: any) => {
            const categoryArray = categoryResponse.data.map((category: any) => {
                return {
                    name: category.categoryName,
                    iconUrl: category.categoryImage,
                    id: category.categoryId
                }
            }).filter((category: any) => {
                return category.name !== 'MULTIPLE_PACKS' && category.name !== 'New Arrivals' && category.name !== 'Best Sellers'
            })

            categoryArray.pop();

            // if(isHomePage){
            //     setCategoryList(categoryArray.slice(0,5))
            // }

            // else{
            setCategoryList(categoryArray)


        })
    }, [])
    const navigate = useNavigate();

    const productsList = [{
        name: 'Vegetables',
        iconUrl: VeggieBasket

    },
    {
        name: 'Dairy, Bread & Eggs',
        iconUrl: BreadLoaf
    },
    {
        name: 'Fresh Meat',
        iconUrl: MeatPacket
    },
    {
        name: 'Rice',
        iconUrl: RiceBag
    },
    {
        name: 'Masala & More',
        iconUrl: MasalaPacket
    }]

    return (
        <div>
            {isHeaderNeeded && <div className={styles.category_carousal_text_container}>
                <div>
                    <div className={styles.category_carousal_title}>Shop by Top Category</div>
                    <div>Explore Excellence in Every Category! Shop by top category at ARR Fine Foods</div>
                </div>
                {/* <Button text='View All' onClick={() => {navigate('/categories')}} className={styles.view_all_button}/> */}
            </div>}

            {isMobile && isHomePage ? <div className={styles.carousal_container}>
                {categoryList.map((item: any) => {
                    return <div className={styles.carousal_item_container} onClick={() => callbackFunction(item.id)}>
                        <div className={styles.background_circle_carousal}>
                            <img src={item.iconUrl} alt={item.name} className={styles.background_image_carousal} />
                        </div>
                        <div>{item.name}</div>
                    </div>
                })
                }


                <div className={styles.carousal_item_container}>
                    <div className={styles.background_circle_carousal} onClick={() => { navigate('/categories') }}>
                        <img src={'https://arrfinefoods.in/wp-content/uploads/2024/08/pngegg9.png'} className={styles.background_image_carousal} />
                    </div>
                    <div style={{ marginRight: '14.5px' }}>MORE</div>
                </div>
            </div> : isMobile && !isHomePage ? 
            <div className={styles.mobile_category_select_container}>
            <label className={styles.mobile_category_select_label}>Select Category</label>
            <Select 
            placeholder='Select any category' 
            className={styles.mobile_category_select} 
            options={categoryList.map((category: any) => {
                return { id: category.id, name: category.name, value: category.name, iconUrl: category.iconUrl , label: category }
            })} 
            optionRender={(option: any) => {
                return <div>
                    <img src={option.data.iconUrl} alt={option.data.name} style={{ width: '32px', height: '32px', marginRight: '10px' }} />
                    {option.data.name}
                </div>
            }} 
            onChange={(e, category: any) => { callbackFunction(category.id) }} 
            optionLabelProp='label'
            labelRender={(option:any) => {
                return <div className={styles.category_select_value}>
                    <img src={option.label.iconUrl} alt={option.label.name} style={{ width: '32px', height: '32px', marginRight: '10px' }} />
                    {option.label.name}
                </div>
            }}
            /> </div> : <div className={styles.categories_grid}>
                {categoryList.map((item: any) => {
                    return <div className={styles.carousal_item_container} onClick={() => callbackFunction(item.id)}>
                        <div className={styles.background_circle_carousal}>
                            <img src={item.iconUrl} alt={item.name} className={styles.background_image_carousal} />
                        </div>
                        <div>{item.name}</div>
                    </div>
                })

                }
            </div>}




        </div>
    )
}

export default CategoryCarousal