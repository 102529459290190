import axios from 'axios';

export const getImageForDashboard = async (imageId:string) => {
    try{
    return await axios.get(`${process.env.REACT_APP_ARRFINE_BASE_URL}/dashboard/${imageId}`);
    }catch(err){
        return err;
    }
}

export const getDeliveryAvailability = async (pincode:string) => {
    try{
    return await axios.get(`${process.env.REACT_APP_ARRFINE_BASE_URL}/delivery/check/${pincode}`);
    }catch(err){
        return err;
    }
}