// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aboutPage_about_page_container__MCrX7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin: 50px;
}

.aboutPage_about_page_header__pxMy3 {
  font-size: 20px;
  font-weight: bold;
}

.aboutPage_about_page_content__uvNFx {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.aboutPage_about_page_content__uvNFx > p {
  color: #515d66;
  line-height: 1.7;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/views/AboutPage/aboutPage.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,YAAA;AACJ;;AAEA;EACI,eAAA;EACA,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACI;EACI,cAAA;EACA,gBAAA;EACA,eAAA;AACR","sourcesContent":[".about_page_container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 50px;\n    margin: 50px;\n  }\n\n.about_page_header{\n    font-size: 20px;\n    font-weight: bold;\n}\n\n.about_page_content{\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n\n    >p{\n        color: #515d66;\n        line-height: 1.7;\n        font-size: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"about_page_container": `aboutPage_about_page_container__MCrX7`,
	"about_page_header": `aboutPage_about_page_header__pxMy3`,
	"about_page_content": `aboutPage_about_page_content__uvNFx`
};
export default ___CSS_LOADER_EXPORT___;
