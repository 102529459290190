import React, { useContext, useEffect, useState } from 'react'
import UserDetailsSideNav from './UserDetailsSideNav'
import OrdersPage from './OrdersPage';
import AccountDetails from './AccountDetails';
import SavedAddress from './SavedAddress';
import RewardsPage from './RewardsPage';
import styles from './UserDetailsPage.module.scss';
import { handleResize } from '../../utils/checkWindowSize';
import { Spin } from 'antd';
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getAllAddressforUser, getUserDetails } from '../../config/axios/userApis';
import { getOrderDetails } from '../../config/axios/orderApis';
import { CartContext, WishListContext } from '../../config/context/CartContext';

const UserDetailsPage = () => {
  const [isMobile,setIsMobile] = useState<boolean>(false);
  const [onMainTab, setOnMainTab] = useState<boolean>(true);
  const [activeTab,setActiveTab] = useState<string>('account');
  const [pageLoader,setPageLoader] = useState<boolean>(false);
  const handleActiveTab = (activeTab: string) => {
    setActiveTab(activeTab);
  }

  const [userDetails,setUserDetails] = useState<any>({})
  const [orderDetails,setOrderDetails] = useState<any>([]);
  const [addressDetails,setAddressDetails] = useState<any>([]);

  const {clearCart} = useContext(CartContext);
  const {clearwishList} = useContext(WishListContext);

  const navigate = useNavigate();

  const getUserData = async () => {
    // Fetch user details from API
    const userDataResponse:any =  await getUserDetails(localStorage.getItem('userId')!);
    const orderResponse:any = await getOrderDetails(localStorage.getItem('userId')!);
    const addressResponse:any = await getAllAddressforUser(localStorage.getItem('userId')!);
    const addressArray = addressResponse.data.map((addressObject:any) => {
      if(Array.isArray(addressObject.address)){
      return {...addressObject.address[0],addressId:addressObject.addId};
      }
      else{
        return {...addressObject.address,addressId:addressObject.addId};
      }
    })
    return {userData: userDataResponse.data, orderData: orderResponse.data, addressData: addressArray};
  }

  useEffect(() => {
      setPageLoader(true);
      getUserData().then((dataResponse:any) => {
         setUserDetails(dataResponse.userData)
         setOrderDetails(dataResponse.orderData)
         setAddressDetails(dataResponse.addressData)
      });
      const response =  handleResize();
      if(response && onMainTab){
        setActiveTab('')
        setOnMainTab(true)
      }
      setIsMobile(response);
      setPageLoader(false);
  },[])

  useEffect(() => {
    if(activeTab === ''){
      setOnMainTab(true);
    }
    else{
      setOnMainTab(false);
    }
  },[activeTab])

  const toggleActiveTab = () => {
     switch(activeTab) {
        case 'orders':
          return <OrdersPage ordersData={orderDetails}/>;
        case 'account':
          return <AccountDetails userData={userDetails}/>;
        case 'address':
          return <SavedAddress addressData={addressDetails}/>;
        case 'rewards':
          return <RewardsPage />;
        case 'signout':
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          clearCart();
          clearwishList();
          navigate('/');
          window.location.reload();
          return null;
        default:
          return <></>;
     }
  }

  if(pageLoader){
    return <Spin 
    indicator={<LoadingOutlined />}
    style={{ fontSize: 24 }}
    spinning/>;
  }

  return (
   <div className={styles.user_details_page_container}>
    <UserDetailsSideNav tabSwitchFunction={(activeTab) => handleActiveTab(activeTab)} isFullWidth={isMobile && onMainTab} isHidden={isMobile && !onMainTab}/>
    <div className={(isMobile && onMainTab) ? styles.empty_right_container :styles.user_details_right_container}>
    {isMobile && !onMainTab && <div className={styles.go_back_button}>
      <ArrowLeftOutlined />
      <div onClick={() => {setActiveTab('');}}>Back</div>
    </div>}
    {toggleActiveTab()}
    </div>

   </div>
  )
}

export default UserDetailsPage