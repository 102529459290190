import { AppleOutlined } from '@ant-design/icons'
import PlayStoreIcon from '../../assets/images/Icon_playstore.svg'
import MobileImageOutline from '../../assets/images/Rectangle 62_mobile_outline.svg'
import styles from './homepage.module.scss'

const MobileAppLaunch = () => {

  return (
    <div className={styles.mobile_launch_container}>
    <div>
        <div>
                <h3>Download Fresh Market Mobile App</h3>
                <div>Get the best shopping experience on your mobile device.</div>
        </div>
        <div className={styles.download_buttons_container}>
            <div>
                <AppleOutlined className={styles.apple_icon}/>
                <div>Download on the <span>APP Store</span></div>
            </div>
            <div>
                <img src={PlayStoreIcon} />
                <div>Get it on <span>Google Play</span></div>
            </div>
        </div>
    </div>
    <div>
        <img src={MobileImageOutline} />
    </div>
</div>
  )
}

export default MobileAppLaunch