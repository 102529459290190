import CarOutlined from '@ant-design/icons/lib/icons/CarOutlined'
import styles from './topLevelMessage.module.scss';

const TopLevelMessage = () => {
  return (
    <div className={styles.top_level_message_container}>
        <CarOutlined />
        <div><b>Free Delivery</b> on orders above £35. Currently we do delivery only in Plymouth area.</div>
    </div>
  )
}

export default TopLevelMessage