import { Button, Input } from 'antd'
import styles from './login.module.scss'
import { useState } from 'react';
import VerifyEmailAddressPage from './VerifyEmailAddressPage';
import ResetPassword from './ResetPassword';

const ForgotPassword = () => {
  const [resetPasswordPage, setResetPasswordPage] = useState<boolean>(false);
  const [verifyEmailPage, setVerifyEmailPage] = useState<boolean>(false);

  const verificationCodeHandler = (code: string) => {
      console.log('Code',code)
      setVerifyEmailPage(false);
      setResetPasswordPage(true);
  }

  const resetPasswordHandler = (password: string) => {
      console.log('Password',password)
      setResetPasswordPage(false);
      //send password to server
  }

  if(verifyEmailPage){
    return <VerifyEmailAddressPage verifyHandler={(code:string) => verificationCodeHandler(code)}/>
  }
  if(resetPasswordPage){
    return <ResetPassword resetPasswordHandler={(password:string) => resetPasswordHandler(password)}/>
  }
  return (
    <div className={styles.login_container}>
        <div className={styles.forgot_password_heading_container}>
            <div className={styles.login_title}>Forget Password</div>
            <div>Enter the email address or mobile phone number associated with your ARR Fine Foods</div>
        </div>
        <div className={styles.forgot_password_field}>
            <div className={styles.forgot_password_label}>Email</div>
            <Input type='email'/>
        </div>
        <Button type='primary' onClick={() => {setVerifyEmailPage(true)}}>Send Code</Button>

    </div>
  )
}

export default ForgotPassword