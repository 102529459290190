import { useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
import styles from './billSummary.module.scss';
import { useEffect, useRef } from 'react';

interface BillSummaryProps{
  isButtonAvailable: boolean;
  billDetails: any;
}

const BillSummary = ({isButtonAvailable,billDetails}:BillSummaryProps) => {
  const navigate = useNavigate();
  const {totalPrice,totalQuantity,shipmentFee} = billDetails

 const updatedShipmentFee = useRef<any>(shipmentFee)

  useEffect(() => {
    if(totalPrice < 35){
         updatedShipmentFee.current = (shipmentFee + 5)
    }

  },[billDetails])

  return (
    <div className={styles.bill_summary_container}>
      <div className={styles.product_summary_title}>Product Summary</div>
      <div className={styles.subitems_list_container}>
        <div className={styles.subitems_container}>
          <div><b>Subtotal</b> ({totalQuantity} { totalQuantity > 1 ? `items` : 'item' })</div>
          <div><b>{`\u00A3`} {totalPrice}</b></div>
        </div>
        <div className={styles.subitems_container}>
          <div><b>Shipping Charges</b></div>
          <div><b>{`\u00A3`} {updatedShipmentFee.current}</b></div>
        </div>
      </div>
      <div className={styles.divider_light}/>
      <div className={styles.price_container}>
        <div className={styles.estimated_total_container}>
          <div><b>Estimated Total</b></div>
          <div><b>{`\u00A3`} {+totalPrice + +updatedShipmentFee.current}</b></div>
        </div>
        <div className={styles.tax_prompt}>Applicable taxes will be calculated at checkout.</div>
        <div className={styles.promo_code_container}>
          <div>Have a promo code ?</div>
          <div>Code will work on checkout page</div>
        </div>
        <div className={styles.apply_code}>Apply Code</div>
        <div className={styles.divider_bold}/>
        <div className={styles.total_container}>
          <div><b>Total</b></div>
          <div><b>{`\u00A3`} {+totalPrice + +updatedShipmentFee.current}</b></div>
        </div>
      </div>
      {isButtonAvailable && <Button text='Continue to checkout' type='primary' onClick={() => {navigate('/billing')}} className={styles.checkout_button}/>}
    </div>
  )
}

export default BillSummary