import { useEffect, useState } from 'react';
import styles from './UserDetailsPage.module.scss';
import { Button, Form, Input } from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import cn from 'classnames';
import { addAddressforUser, updateAddressForUser } from '../../config/axios/userApis';
import { toast } from '../../components/Toaster/ToasterComponent';


interface AddressDetailsProps{
  firstName: string,
  lastName: string,
  secondLane: string,
  firstLane: string,
  city: string,
  state: string,
  zipCode: string,
  phoneNumber:string,
  email:string
}

interface AddressDetailsComponentProps {
  toggleView: () => void;
  editMode?: boolean;
  editCallBack ?: any;
  addressData:any;
  clearData : any;
}

interface SavedAddressDetailsProps{
  addressData:any;
}

const AddressDetails = ({toggleView,addressData,clearData,editMode,editCallBack}:AddressDetailsComponentProps) => {
  console.log("AID",addressData)
  const [form] = Form.useForm();
  const [buttonLoader,setButtonLoader] = useState<boolean>(false);

  useEffect(() => {
    if(editMode && addressData){
    form.setFieldsValue({
      firstName: addressData.firstName || '',
      lastName: addressData.lastName || '',
      phoneNumber: addressData.phoneNumber || '',
      firstLane: addressData.firstLane || '',
      secondLane: addressData.secondLane || '',
      city: addressData.city || '',
      state: addressData.state || '',
      zipCode: addressData.zipCode || '',
      email: addressData.email || '',
      password: addressData.password || '',
    });
  }
  }, [addressData, form]);

  const onFinish = async (values:any) => {
    setButtonLoader(true);
    if(!editMode){
    const addressResponse = await addAddressforUser(localStorage.getItem('userId')!, values);
    if(addressResponse.status === 200){
      toast.success(`Address added successfully`)
      toggleView();
      clearData();
    }
    else{
      toast.error('Error in Adding address');
    }
    setButtonLoader(false);
  }
  else{
    const addressResponse = await updateAddressForUser(addressData.addressId,localStorage.getItem('userId')!, values);
    if(addressResponse.status === 200){
      toast.success(`Address updated successfully`)
      editCallBack();
      clearData();
    }else{
      toast.error('Error in Updating address');
    }
    setButtonLoader(false);
  }
  };
  return <div className={styles.address_list_item}>
    <div className={styles.address_details_title}>
      <div>+ Add Address</div>
      <div>*Required Fields</div>
    </div>
    <Form className={styles.address_form_container} onFinish={onFinish} form={form}>
        <div className={cn(styles.form_fields_container,styles.address_fields)}>
          <div>
            <div className={styles.form_field_label}>First Name*</div>
            <Form.Item name="firstName">
              <Input   className={styles.form_field_input}/>
            </Form.Item>
          </div>
          <div>
           <div className={styles.form_field_label}>Last Name*</div>
          <Form.Item name="lastName">
            <Input  className={styles.form_field_input}/>
          </Form.Item>
          </div>
        </div>
        <div className={cn(styles.form_fields_container,styles.address_fields)}>
        <div>
           <div className={styles.form_field_label}>Street Address*</div>
          <Form.Item name="secondLane">
            <Input  className={styles.form_field_input}/>
          </Form.Item>
          </div>
          <div>
           <div className={styles.form_field_label}>Apt, flat, etc (optional)</div>
          <Form.Item name="firstLane">
            <Input   className={styles.form_field_input}/>
          </Form.Item>
          </div>
        </div>
        <div className={cn(styles.form_fields_container,styles.address_fields)}>
        <div>
           <div className={styles.form_field_label}>City*</div>
          <Form.Item name="city">
            <Input   className={styles.form_field_input}/>
          </Form.Item>
          </div>
          <div>
           <div className={styles.form_field_label}>State*</div>
          <Form.Item name="state">
            <Input className={styles.form_field_input}/>
          </Form.Item>
          </div>
        </div>
        <div className={cn(styles.form_fields_container,styles.address_fields)}>
          <div>
           <div className={styles.form_field_label}>Zip code*</div>
            <Form.Item name="zipCode">
              <Input  className={styles.form_field_input}/>
            </Form.Item>
          </div>
          <div>
           <div className={styles.form_field_label}>Phone Number*</div>
          <Form.Item name="phoneNumber">
            <Input   className={styles.form_field_input}/>
          </Form.Item>
          </div>
        </div>
        <div className={cn(styles.form_fields_container,styles.address_fields)}>
        <div>
           <div className={styles.form_field_label}>Email Address*</div>
            <Form.Item name={"email"}>
              <Input className={styles.form_field_input}/>
            </Form.Item>
        </div>
        </div>
        <div className={cn(styles.form_fields_container,styles.address_fields)}>
          <div className={styles.form_checkbox_container}>
            <Checkbox />
            <div>
            Set as my preferred delivery address
            </div>
          </div>
          <div className={styles.form_button_container}>
          <div onClick={() => {toggleView();clearData();} } className={styles.address_form_cancel}><u>Cancel</u></div>
            <Button htmlType='submit' type='primary' loading={buttonLoader} className={styles.form_submit_button}>Save</Button>
         </div>
        </div>
      </Form>
  </div>

}

const SavedAddress = ({addressData}:SavedAddressDetailsProps) => {
  const [addressDetailsView, setAddressDetailsView] = useState<boolean>(false)
  const [savedAddressData, setSavedAddressData] = useState<AddressDetailsProps[]>([]);
  const [editMode,setEditMode] = useState<boolean>(false);
  const [addressToEdit, setAddressToEdit] = useState<AddressDetailsProps>({
    firstName: '',
    lastName: '',
    secondLane: '',
    firstLane: '',
    city: '',
    state: '',
    zipCode: '',
    phoneNumber: '',
    email: ''
  });
  useEffect(() => {
    if(!addressData || addressData === null){
       setSavedAddressData([])
       return;
    }
     const addressArray = addressData.map((address:any) => {
      return {
          firstName : address. firstName || 'Vinish',
          lastName : address.secondName || 'Vin',
          firstLane : address.apt_flat_name || '14-16 Victoria Rd',
          secondLane : address.streetAddress || 'St Budeaux',
          city : address.city || 'Plymouth',
          state: address.state || '',
          zipCode: address.zipCode || 'PL5 1RG',
          phoneNumber: address.phNumber || '+44 123 456 789',
          email: address.email || 'vinish@example.com',
          addressId: address.addressId
      }
     })
     setSavedAddressData(addressArray)
  },[addressData])
  return (
    <div>
      <div className={styles.address_section_heading}>
        <div>Address</div>
        <div onClick={() => setAddressDetailsView(true)}>+ Add Address</div>
      </div>
      <div className={styles.address_list_container}>
        {addressDetailsView ? <AddressDetails toggleView={() => setAddressDetailsView(false)} addressData={addressToEdit} editMode={editMode} editCallBack={() => {setAddressDetailsView(false) ; window.location.reload()}} clearData={() => setAddressToEdit({
          firstName: '',
          lastName: '',
          secondLane: '',
          firstLane: '',
          city: '',
          state: '',
          zipCode: '',
          phoneNumber: '',
          email: ''
        })}/> : <div>
          { savedAddressData && savedAddressData.length > 0 ? savedAddressData.map((addressData) => {
              return  <div className={styles.address_list_item}>
              <div className={styles.address_name}>{addressData.firstName} {addressData.lastName}</div>
              <div>{addressData.firstLane}, {addressData.secondLane} {addressData.zipCode}, United Kingdom</div>
              <div className={styles.footer_container}>
                <div className={styles.contact_details}>
                  <div>Phone Number:</div>
                  <div>{addressData.phoneNumber}</div>
                </div>
                <div>
                  <Button type='primary' onClick={() => {setAddressDetailsView(true);setAddressToEdit(addressData);setEditMode(true);}}>Edit Address</Button>
                  <div><u>Remove</u></div>
                </div>
              </div>
            </div>
          }) : <div>No Saved Address for this user</div>}
         
          </div>}
      </div>
      
    </div>
  )
}

export default SavedAddress