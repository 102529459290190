import { HeartFilled } from "@ant-design/icons"
import {Card, List } from "antd"
import styles from './productListing.module.scss'
import Button from "../../components/Button/Button"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { CartContext, WishListContext } from "../../config/context/CartContext";
import cn from 'classnames';


const ProductListing = (props:any) => {
    const [isMobile, setIsMobile] = useState(false)
    const location = useLocation();
 
//choose the screen size 
const handleResize = () => {
  if (window.innerWidth < 720) {
      setIsMobile(true)
  } else {
      setIsMobile(false)
  }
}

const {addToCart} = useContext(CartContext);
const {wishListItems,addTowishList,removeFromwishList} = useContext(WishListContext)

const checkIteminWishList = (item:any) => {
    return wishListItems.some((sampleItem) => sampleItem.id === item.id)
}

// create an event listener
useEffect(() => {
  handleResize();
  window.addEventListener("resize", handleResize)
},[props.data])
    const navigate = useNavigate();

    if(isMobile){
        return <div className={styles.mobile_product_grid}>
            {props.data?.map((item:any) =>  <Link to={`/product/${item.id}`} className={styles.card_link}><Card hoverable={true} bordered={false} size='small'cover={<div className={styles.card_image_background}>
                <img alt='Product Image' src={item.imageUrl} className={styles.product_image}/>
                <HeartFilled className={`${styles.like_icon} ${checkIteminWishList(item) ? styles.wishlist_icon : ''}`} onClick={() => {checkIteminWishList(item) ? removeFromwishList(item) : addTowishList(item)}}/>
                </div>}>
                    <div>
                        <div className={styles.best_seller_label}>Best Seller</div>
                        <div className={styles.product_card_title} onClick={() => navigate(`/product/${item.id}`)}>{item.title}</div>
                        {/* <div>{item.description}</div> */}
                        <div className={styles.product_price_container}>
                            <div>{`\u00A3`} {item.price}</div>
                            <Button onClick={() => {addToCart(item)}} text="" className={styles.add_cart_button} iconType="shopcart"/>
                        </div>
                    </div>
                </Card></Link>)}
        </div>
    }

    if(location.pathname === '/'){
        return <div className={styles.product_card_container}>
            {
                props.data.map((item:any) => {
                    return (
                        
                            <Link to={`/product/${item.id}`} className={styles.card_link}>
                            <Card hoverable={true} bordered={false} className={styles.homepage_product_card} cover={<div className={styles.card_image_background}>
                            <img alt='Product Image' src={item.imageUrl} className={styles.product_image}/>
                            <HeartFilled className={`${styles.like_icon} ${checkIteminWishList(item) ? styles.wishlist_icon : ''}`} onClick={() => {checkIteminWishList(item) ? removeFromwishList(item) : addTowishList(item)}}/>
                            </div>}>
                                <div>
                                    <div className={styles.best_seller_label}>Best Seller</div>
                                    <div className={styles.product_card_title}>{item.title}</div>
                                    <div>{item.description}</div>
                                    <div className={styles.product_price_container}>
                                        <div>{`\u00A3`} {item.price}</div>
                                        <Button onClick={() => {addToCart(item)}} text="Add Cart" className={styles.add_cart_button} iconType="shopcart"/>
                                    </div>
                                </div>
                            </Card>
                            </Link>
                    )
                })
            }
        </div>
    }

  return (
     <List
    itemLayout="horizontal"
    grid={{ gutter:30, column:4}}
    dataSource={props.data}
    className={cn(styles.product_list_grid,{[styles.homepage_product_grid]: location.pathname === '/'})}
    renderItem={(item:any) => {
        return (
            <List.Item>
                <Link to={`/product/${item.id}`} className={styles.card_link} style={{ height: "100%" }}>
                    <Card hoverable={true} bordered={false} className={styles.product_card} cover={<div className={styles.card_image_background}>
                    <img alt='Product Image' src={item.imageUrl} className={styles.product_image}/>
                    <HeartFilled className={`${styles.like_icon} ${checkIteminWishList(item) ? styles.wishlist_icon : ''}`} onClick={() => {checkIteminWishList(item) ? removeFromwishList(item) : addTowishList(item)}}/>
                    </div>}>
                        <div>
                            <div className={styles.best_seller_label}>Best Seller</div>
                            <div className={styles.product_card_title} onClick={() => navigate(`/product/${item.id}`)}>{item.title}</div>
                            <div className={styles.product_description}>{item.description}</div>
                            <div className={styles.product_price_container}>
                                <div>{`\u00A3`} {item.price}</div>
                                <Button onClick={() => {addToCart(item)}} text="Add Cart" className={styles.add_cart_button} iconType="shopcart"/>
                            </div>
                        </div>
                    </Card>
                </Link>
            </List.Item>
        )
    }} 
    />
   
  )
}

export default ProductListing