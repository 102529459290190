import { Route, Routes } from 'react-router-dom'
import HomePage from '../../views/HomePage/HomePage'
import CategoriesPage from '../../views/CategoriesPage/CategoriesPage'
import ProductPage from '../../views/ProductPage/ProductPage'
import Billing from '../../views/BillingPage/Billing'
import CartPage from '../../views/CartPage/CartPage'
import UserDetailsPage from '../../views/UserDetailsPage/UserDetailsPage'
import Login from '../Login/Login'
import SuccessPage from '../../views/SuccessPage/SuccessPage'
import FailurePage from '../../views/FailurePage/FailurePage'
import AboutPage from '../../views/AboutPage/AboutPage'
import ContactUsPage from '../../views/ContactUsPage/ContactUsPage'
import ForgotPassword from '../Login/ForgotPassword'
import axios from 'axios'
import MultipackPage from '../../views/CategoriesPage/MultipackPage'
import NewArrivalsPage from '../../views/CategoriesPage/NewArrivalsPage'

const MainLayout = () => {
  function NoMatch() {
    return (
      <div style={{ padding: 20 }}>
        <h2>404: Page Not Found</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adip.</p>
      </div>
    );
  }
  return (
   
        <Routes>
            <Route path='/categories' element={<CategoriesPage/>}/>
            <Route path='/multipacks' element={<MultipackPage/>}/>
            <Route path='/newarrivals' element={<NewArrivalsPage/>}/>
            <Route path="/product/:productId" element={<ProductPage />} />
            <Route path='/billing' element={localStorage.getItem('token') && localStorage.getItem('token') !== '' ?<Billing /> : <Login />} />
            <Route path='/cart' element={<CartPage />} />
            <Route path='/user' element={localStorage.getItem('token') && localStorage.getItem('token') !== '' ? <UserDetailsPage /> : <Login />} />
            <Route path='/login' element={<Login />}/>
            <Route path='/forgotpassword' element={<ForgotPassword />} />
            <Route path='/success' element={<SuccessPage />} />
            <Route path='/failure' element={<FailurePage />} />
            <Route path='/aboutUs' element={<AboutPage />} />
            <Route path='/contact' element={<ContactUsPage />} />
            <Route path='/' element={<HomePage />}/>
            <Route path="*" element={<NoMatch />} />

        </Routes>
  )
}

export default MainLayout