import Button from '../../components/Button/Button'
import CategoryCarousal from './CategoryCarousal'
import styles from './homepage.module.scss'
import TeaBox from '../../assets/images/image 48_taj_mahal.png'
import ProductListing from '../ProductListing/ProductListing'
import NewArrivals from './NewArrivals'
import BrandsList from '../../assets/images/Group 39513_brands_list.png';
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect, useMemo, useState } from 'react'
import { getListofProducts, getProductById, getProductCategoryById, getProductsInCategory } from '../../config/axios/productApis'
import Testimonials from './Testimonials'
import MobileAppLaunch from './MobileAppLaunch'
import cn from 'classnames';
import { getDeliveryAvailability, getImageForDashboard } from '../../config/axios/dashboardApis'
import { CartContext } from '../../config/context/CartContext'
import { Input, Modal, Spin } from 'antd'
import { EnvironmentOutlined } from '@ant-design/icons'


export interface ProductListProps{
  imageUrl: string,
  title: string,
  description: string,
  price: string | number,
  id: string,
}

type ImageData = {
 imageId: number,
 imageUrl: string,
}

const HomePage = () => {

  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [productList, setProductList] = useState<ProductListProps[]>([{
    imageUrl: '',
    title: '',
    description: '',
    price: 0,
    id: '',
  }])

  const [zipCodePopup, setZipCodePopup] = useState<boolean>(false)
  const [deliveryZipCode, setDeiveryZipCode] = useState<string>('');
  const [deliveryavailable, setDeliveryAvailable] = useState<boolean | null>(null);
  const [dashboardImages, setDashboardImages] = useState<ImageData[]>([]);
  const [pageLoader, setPageLoader] = useState<boolean>(false);



    //choose the screen size 
    const handleResize = () => {
      if (window.innerWidth < 720) {
          console.log('It is a mobile screen')
          setIsMobile(true)
      } else {
          console.log('It is not a mobile screen')
          setIsMobile(false)
      }
  }


  const getImagesData = async () => {
    const imageArray:ImageData[] = []
    for (let i=1 ; i<10;i++){
      const imageResponse: any = await getImageForDashboard(i.toString());
      if(imageResponse && imageResponse.data.imageUrl){
        imageArray.push({imageId: imageResponse.data.imageId,
          imageUrl: imageResponse.data.imageUrl,

        })
      }
    }
    return imageArray;
  }

  const debouncedSearch = useMemo(() => {
    const delay = 1500; // Debounce time in milliseconds
    let timeoutId: any;

    return (term: string) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setDeiveryZipCode(term);
        }, delay);
    };
}, [deliveryZipCode]);

const handleChange = (data: any) => {
  setDeliveryAvailable(null);
  const term = data;
  debouncedSearch(term);
};

  const {zipCode,setZipCode} = useContext(CartContext)


  useEffect(() => {
    if(deliveryZipCode !== ''){
    getDeliveryAvailability(deliveryZipCode).then((availability:any) => {
      if(availability.status === 200){
        setDeliveryAvailable(true);
        setZipCode(deliveryZipCode);
        setTimeout(() => {
          setZipCodePopup(false);
        },5000)
      }
      else{
        setDeliveryAvailable(false);
      }
    })
    }


  },[deliveryZipCode])

  useEffect(() => {
    handleResize();
    setPageLoader(true);
     // Check local storage to determine if the popup should be shown
     const hasShownPopup = localStorage.getItem('popupShown');
     getImagesData().then((data:any) => {
      setDashboardImages(data)
      setPageLoader(false);
     });
    if(zipCode === '' && !hasShownPopup) {
      setZipCodePopup(true);
      localStorage.setItem('popupShown', 'true');
    }
    getProductCategoryById('18').then((productsResponse:any) => {
      const valueArray: any[] = [];
      const productArray = JSON.parse(productsResponse.data.arrayOfProduct);
      Promise.all(
        productArray.map((productId: number) => 
          getProductById(productId.toString()).then((product: any) => {
            const productData = product.data;
            return {
              title: productData.productName,
              description: productData.description,
              price: productData.price,
              id: productData.productId,
              imageUrl: productData.productImage
            };
          })
        )
      ).then((results) => {
        valueArray.push(...results);
        setProductList(valueArray);
      }).catch((error) => {
        console.error("Error fetching products:", error);
        setPageLoader(false);
      });
    })
  },[])

  if(pageLoader){
    return (

      <div className={styles.loader_container}>
     
        <Spin tip="Loading" size='large'></Spin>
      
      </div>
     
    )
  }


  return (
    <div className={styles.homepage_container}>
        <div className={styles.veggie_container}>
            <img src={dashboardImages.find((image) => isMobile ? image.imageId === 8 : image.imageId === 1)?.imageUrl} alt="Main Image" />
        </div>
        <CategoryCarousal callbackFunction={(id:any) => {navigate('/categories',{state:id})}} isHomePage={true}/>
        <div className={styles.pickup_sale_container}>
          
            <img src={dashboardImages.find((image) => isMobile ? image.imageId === 9 : image.imageId === 2)?.imageUrl} alt="Left Image" className={styles.left_veggie_container}/>
        
          <img src={dashboardImages.find((image) => image.imageId === 3)?.imageUrl} className={styles.right_veggie_container} alt="Right Image"  />
        </div>
        <div className={styles.best_sellers_container}>
           <div className={styles.best_sellers_head}>
              <div className={styles.best_sellers_text}>
                <div>Best Sellers</div>
                <div>Discover our most popular items that our customers can't get enough of. From fresh produce to gourmet delights, these top picks are must-haves for your pantry. Experience the quality and flavor that makes them best sellers!</div>
              </div>
              <Button text='View All' onClick={() => {navigate('/categories')}} className={cn(styles.view_all_button,styles.best_sellers_mobile)} />
            </div>
            <ProductListing data={productList} />
            <NewArrivals imageData={dashboardImages} isMobile={isMobile}/>
            <div className={styles.brands_container}>
              <div>
                <div>Popular Brands</div>
                <div>Explore top brands that customers love and trust.</div>
              </div>
               <div className={styles.popular_brands_grid}>
                <img src={'https://arrfinefoods.in/wp-content/uploads/2024/08/jacme_logo_154b79ef-a1b6-46a7-91e9-4333946b413a-ezgif.com-webp-to-png-converter-removebg-preview.png'} />
                <img src={'https://arrfinefoods.in/wp-content/uploads/2024/08/logo-header.png'} />
                <img src={'https://arrfinefoods.in/wp-content/uploads/2024/08/png-transparent-maggi-hd-logo-removebg-preview.png'} />
                <img src={'https://arrfinefoods.in/wp-content/uploads/2024/08/pngwing.com9_-1.png'} />
                <img src={'https://arrfinefoods.in/wp-content/uploads/2024/08/princefood-removebg-preview1.png'} />
                <img src={'https://arrfinefoods.in/wp-content/uploads/2024/08/Aachi-masala.png'} />
                <img src={'https://arrfinefoods.in/wp-content/uploads/2024/08/aashirvaad-vector-logo-removebg-preview.png'} />
                <img src={'https://arrfinefoods.in/wp-content/uploads/2024/08/images-removebg-preview.png'} />
               </div>
            </div>
        </div>
        <MobileAppLaunch />
        <Testimonials />
        <Modal open={zipCodePopup} footer={false} onCancel={() => setZipCodePopup(false)}>
        <>
        <img src='https://arrfinefoods.in/wp-content/uploads/2024/09/delivery.gif' height={200} width={200} className={styles.deliver_container_image}/>
        <div className={styles.deliver_container_header}>SELECT LOCATION</div>
        <div className={styles.deliver_to_container}>
            <div>
            
            <div><span><Input placeholder='Enter ZIP Code' onChange={(e) => {handleChange(e.target.value)}} className={styles.zip_code_container}/></span></div>
            </div>
            {deliveryavailable ? <div className={styles.delivery_available_text}>Delivery available for this location</div> : deliveryavailable === false ? <div className={styles.delivery_unavailable_text}>Oops ! Delivery Unavailable for this location. Please try another zipcode</div> : ''}
        </div> 
        </>
        </Modal>
    </div>
  )
}

export default HomePage