import React, { useEffect, useState } from 'react'
import styles from './footer.module.scss';
import { EnvironmentOutlined, FacebookOutlined, InstagramOutlined, LinkedinOutlined, PhoneOutlined, TwitterOutlined } from '@ant-design/icons';
import CardImage from '../../assets/images/Group 39476_card_types.png';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/image-removebg-preview.png';

const Footer = () => {
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const navigate = useNavigate();

    //choose the screen size
    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

   useEffect(() => {
    handleResize();
   },[])


  return (
    <div className={styles.footer_container}>
        <div className={styles.footer_fields_container}>
            <div className={styles.brand_name_footer_container}>
                <div><img src={Logo} height={85} width={250}/></div>
                <div>Shopping here is like having a farmers' market at my doorstep—fresh, convenient, and always reliable!</div>
            </div>
           {isMobile ? <div className={styles.quick_links_mobile_container}>
            <div className={styles.quick_links_container}>
                <div>Quick Links</div>
                <div className={styles.links_container}>
                    <div onClick={() => {navigate('/')}}>Home</div>
                    <div onClick={() => {navigate('/categories')}}>Shop</div>
                    <div onClick={() => {navigate('/aboutUs')}}>About Us</div>
                    <div>Blog</div>
                    <div onClick={() => {navigate('/contact')}}>Contact Us</div>
                </div>
            </div>
            <div className={styles.quick_links_container}>
                <div>User Area</div>
                <div className={styles.links_container}>
                    <div onClick={() => navigate('/user')}>My Account</div>
                    <div>My Orders</div>
                    <div>My Reward Points</div>
                    <div>My Coupons</div>

                </div>
            </div>
            <div className={styles.quick_links_container}>
                <div>Legal</div>
                <div className={styles.links_container}>
                    <div>Shipping Policy</div>
                    <div>Refund Policy</div>
                    <div>Cancellation Policy</div>
                    <div>Return Policy</div>
                    <div>FAQ</div>
                </div>
            </div>
            <div className={styles.quick_links_container}>
                <div>Contact Us</div>
                <div className={styles.links_container}>
                    <div className={styles.contact_detail}>
                        <EnvironmentOutlined style={{fontSize:'16px'}}/>
                        <div className={styles.address}>14-16 Victoria Rd, St Budeaux, Plymouth PL5 1RG, United Kingdom</div>
                    </div>
                    <div className={styles.contact_detail}>
                        <PhoneOutlined style={{fontSize:'16px'}}/>
                        <div>+441752271528</div>
                    </div>
                </div>
            </div> 
           </div> : <> <div className={styles.quick_links_container}>
                <div>Quick Links</div>
                <div className={styles.links_container}>
                    <div onClick={() => {navigate('/')}}>Home</div>
                    <div onClick={() => {navigate('/categories')}}>Shop</div>
                    <div onClick={() => {navigate('/aboutUs')}}>About Us</div>
                    <div>Blog</div>
                    <div onClick={() => {navigate('/contact')}}>Contact Us</div>
                </div>
            </div>
            <div className={styles.quick_links_container}>
                <div>User Area</div>
                <div className={styles.links_container}>
                    <div onClick={() => navigate('/user')}>My Account</div>
                    <div>My Orders</div>
                    <div>My Reward Points</div>
                    <div>My Coupons</div>

                </div>
            </div>
            <div className={styles.quick_links_container}>
                <div>Legal</div>
                <div className={styles.links_container}>
                    <div>Shipping Policy</div>
                    <div>Refund Policy</div>
                    <div>Cancellation Policy</div>
                    <div>Return Policy</div>
                    <div>FAQ</div>
                </div>
            </div>
            <div className={styles.quick_links_container}>
                <div>Contact Us</div>
                <div className={styles.links_container}>
                    <div className={styles.contact_detail}>
                        <EnvironmentOutlined style={{fontSize:'16px'}}/>
                        <div className={styles.address}>14-16 Victoria Rd, St Budeaux, Plymouth PL5 1RG, United Kingdom</div>
                    </div>
                    <div className={styles.contact_detail}>
                        <PhoneOutlined style={{fontSize:'16px'}}/>
                        <div>+441752271528</div>
                    </div>
                </div>
            </div> </>}
        </div>
        <div className={styles.payment_social_container}>
         <img src={CardImage} />
         <div className={styles.social_container}>
         <FacebookOutlined className={styles.social_image} />
         <InstagramOutlined className={styles.social_image}/>
         <TwitterOutlined className={styles.social_image}/>
         <LinkedinOutlined className={styles.social_image}/>
        </div> 
        </div>
        <div className={styles.footer_foot_container}>
            <div>Copyright © 2024. ARR Fine Food. All rights reserved.</div>
            <div>Terms & Condition | Privacy Policy</div>
        </div>
    </div>
  )
}

export default Footer